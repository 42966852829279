<template>
    <div class="flex align-center">
        <div class="imglist">
            <div class="imgitem" v-for="(item,index) in myfilelist">
                <div class="imgover">
                    <span @click="handleRemove2(index)">
                        <i class="el-icon-delete"></i>
                    </span>
                </div>
                <img :src="item" v-if="item.indexOf('.jpg')!=-1 || item.indexOf('.jpeg')!=-1 || item.indexOf('.png')!=-1 || item.indexOf('.gif')!=-1" alt="">
                <em v-else>{{namelist[index]}}</em>
            </div>
            <div class="imgitem flex align-center justify-center" v-if="showload">
                <i class="el-icon-loading" style="font-size:40px;"></i>
            </div>
        </div>
        <el-upload style="display:inline-block;vertical-align: middle;" ref="uploads" :file-list="fileList"
            action="/api/qiniu/getImageUrl" :headers="headers" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess"
            list-type="picture-card" :show-file-list="false">
            <i slot="default" class="el-icon-plus"></i>
            <!-- <div slot="file" slot-scope="{file}">
                <img style="vertical-align: middle;"   class="el-upload-list__item-thumbnail" :src="file.response.data?file.response.data:file.response.data" alt="">
                <video :src="file.response.data.video" v-if="file.response.data.image"></video>
                <span class="el-upload-list__item-actions">
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
            </div> -->
        </el-upload>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                headers:{
                    token:localStorage.getItem("token"),
                },
                showload:false,
                myfilelist: [],
                namelist:[],
                fileList: [],
                urlObj: {
                    response: {
                        data: []
                    }
                },
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                urlArr: [],
                file:{
                    response:{
                        data:null
                    }
                }
            }
        },
        props: {
            filetype:{
                type:String,
                default:''
            },
            yuanlist: {
                type: Array,
                default: null
            },
            maxnum:{
                type:Number,
                default:0
            },
            tk:{
                type:Boolean,
                default:false
            },
            needname:{
                type:Boolean,
                default:false
            }
        },
        created() {
            console.log(111)
            console.log(this.yuanlist)
            if (this.yuanlist && this.yuanlist.length>0) {
                this.items = this.yuanlist;
                console.log(232)
                if(!this.needname){
                    console.log(556)
                    this.items.forEach(val => {
                        this.myfilelist.push(val)
                        this.urlObj.url = val;

                        this.urlObj.response.data.push(val);
                        this.fileList.push(this.urlObj);
                        this.urlObj = {
                            response: {
                                data: []
                            }
                        };
                    });
                }else{
                    console.log(676)
                    this.items.forEach(val => {
                        this.myfilelist.push(val.url)
                        this.urlObj.url = val.url;
                        this.namelist.push(val.name)
                        this.urlObj.response.data.push(val.url);
                        this.fileList.push(this.urlObj);
                        this.urlObj = {
                            response: {
                                data: []
                            }
                        };
                    });
                }


            }
        },
        beforeDestroy() {
            this.myfilelist=[]
        },
        methods: {
            changeurl() {
                console.log(78787)
                // var fileurl = this.myfilelist.join(",")
                console.log(this.myfilelist)
                if(!this.needname){
                    this.$emit("changeurl", this.myfilelist)
                }else{
                    let newarr=[]
                    this.myfilelist.forEach((item,index)=>{
                        console.log(item)
                        console.log(index)
                        newarr.push({url:item,name:this.namelist[index]})
                    })
                    this.$emit("changeurl", newarr)
                }


            },
            handleAvatarSuccess(res, file, fileList) {
                //console.log(fileList)
                console.log(res)
                this.showload=false
                if (res.code == 200) {
                    // if(res.data==null || res.data==undefined){
                    //     console.log(11111)
                    //     this.myfilelist.push(res.data[0])


                    // }else{
                    //     console.log(22222)
                    //     this.myfilelist.push(res.data.video)
                    // }
                    this.myfilelist.push(res.data.message)
                    this.changeurl()
                }

            },
            beforeAvatarUpload(file) {
                // console.log(this.myfilelist.length)
                // console.log(file.name)
                this.namelist.push(file.name)
                if(!this.tk){
                    if(this.maxnum!=0){
                        if(this.myfilelist.length>=this.maxnum){
                            this.$message.error("最多只能上传"+this.maxnum+"张！")
                            this.namelist.pop()//删除最后一个元素
                            return false
                        }
                    }

                    const isLt2M = file.size / 1024 / 1024 < 100;
                    console.log(this.filetype)
                    if(this.filetype){
                        const isInd=this.filetype.indexOf(file.type) != -1
                        if (!isInd) {
                            this.$message.error('上传文件格式不允许!');
                            this.namelist.pop()//删除最后一个元素
                            return false
                        }
                    }

                    if (!isLt2M) {
                        this.$message.error('上传文件大小不能超过 100MB!');
                        this.namelist.pop()//删除最后一个元素
                        return false
                    }
                    this.loading = true
                    this.showload=true
                    return isLt2M;
                }else{
                    this.showload=true
                    return true
                }

            },
            handleRemove2(index){
                this.myfilelist.splice(index, 1)
                this.namelist.splice(index, 1)
                this.$refs.uploads.uploadFiles.splice(index, 1)

                this.changeurl()
            },
            handleRemove(file) {
                console.log(this.$refs.uploads.uploadFiles);
                var num = 0
                var ind = 0
                for (let item of this.myfilelist) {
                    if (item == file.response.data[0]) {
                        ind = num
                    }
                    num++
                }
                this.myfilelist.splice(ind, 1)
                this.$refs.uploads.uploadFiles.splice(ind, 1)

                this.changeurl()

                // this.$refs.uploads.clearFiles(file)

            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleDownload(file) {
                console.log(file);
            },
        }
    }
</script>
<style>
    .el-upload-list--picture-card .el-upload-list__item {
        margin: 0 8px 0 0 !important;
    }

</style>

<style scoped>
.imglist{ display: flex; align-items: center; flex-wrap: wrap;}
.imgitem{ width: 148px; display: flex; align-items: center; justify-content: center; height: 148px; position: relative; overflow: hidden; margin-right: 5px; border-radius: 5px; border: #f0f0f0 solid 1px;}
.imgitem img{ width: 100%;}
.imgover{ display: none; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5);}
.imgitem:hover .imgover{ display: flex; align-items: center; justify-content: center;}
.imgover span i{ color:#fff; cursor: pointer; font-size: 18px;}



</style>
