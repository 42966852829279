<template>
    <el-dialog
        title="编辑"
        :visible.sync="showEdit"
        width="30%"
        @opened="open"
        :before-close="close">
        <div>
            <el-form :inline="true" :model="form" label-width="100px" class="demo-form-inline">
                <el-form-item label="预约日期：">
                    <el-date-picker
                        v-model="form.preReportTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="时间段：">
                    <el-select v-model="form.preForwardTimeQuantum" placeholder="时间段">
                        <el-option v-for="item in timeitem" :label="item.title" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="手机号：">
                    <el-input v-model="form.mobile"  style="width:218px;"></el-input>
                </el-form-item>
                <el-form-item label="图片：">
                    <Uploadimg v-if="showUpload" :yuanlist="yuanlist" :needname="false" @changeurl="getImgurl"></Uploadimg>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
import _api from "@/api/index";
import Uploadimg from '@/components/upload/uploadImg.vue'
export default{
    components:{
        Uploadimg,
    },
    props:{
        showEdit:Boolean,
        selectobj:Object
    },
    data(){
        return{
            form:{
                id:null,
                preReportTime:null,
                preForwardTimeQuantum:null,
                mobile:null,
                attachment:null,
            },
            opetion:[],
            yuanlist:[],
            showUpload:false,
            timeitem:[
                {id:1,title:"8:30～9:30"},
                {id:2,title:"9:30～10:30"},
                {id:3,title:"10:30～11:30"},
                {id:4,title:"13:30～14:30"},
                {id:5,title:"14:30～15:30"},
                {id:6,title:"15:30～16:30"},
                {id:7,title:"16:30～17:00"},
            ],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
        }
    },
    created() {

    },
    methods:{
        open(){
            console.log(this.selectobj)
            for(let k in this.form){
                this.form[k]=this.selectobj[k]
            }
            this.yuanlist=this.selectobj.attachment.split(",") || []
            this.form.preForwardTimeQuantum=this.selectobj.preReportTimeQuantum
            this.showUpload=true
        },
        close(){
            this.showUpload=false
            this.$emit("clearselect",null)
            this.$emit("update:showEdit",false)
        },
        changeDate(){
            this.getTime()
        },
        getTime(){
            _api.get('/legal/time',{date:this.form.preReportTime}).then(res=>{
                if(res.code==200){
                    let newData = []
                    res.data.forEach((item) => {
                        if (item.available) {
                            newData.push(item)
                        }
                    })
                    console.log(newData);
                    this.opetion = newData
                }
            })

        },
        checkPhone(){
            var str=this.form.mobile;
            var myreg=/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(str)) {
                this.phoneok=false
                alert("请输入正确的手机号！")
            } else {
                this.phoneok=true
            }
        },
        getImgurl(data){
            console.log(data)
            this.form.attachment=data.join(",")
        },


        confirm(){
            _api.put('/legal',this.form).then(res=>{
                if(res.code==200){
                    this.$message.success("编辑成功！")
                    this.$emit("getList",null)
                    this.close()
                }
            })
        }

    }
}
</script>


