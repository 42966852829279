<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>电子合同推广</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="serarch">
                <div class="searchleft">
                    <el-form :inline="true"  class="demo-form-inline">
                        <el-form-item label="入职日期">
                            <el-date-picker
                            v-model="searchform.preReportTime"
                            type="date"
                            clearable
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="入职时间段">
                            <el-select v-model="searchform.preReportTimeQuantum" style="width:140px;"  placeholder="状态">
                            <el-option label="不限" :value="null"></el-option>
                            <el-option v-for="(item,index) in timeitem" :label="item.title" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="searchform.isReport" style="width:140px;"  placeholder="状态">
                            <el-option label="不限" :value="null"></el-option>
                            <el-option label="已入职" :value="1"></el-option>
                            <el-option label="未入职" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="姓名">
                            <el-input v-model="searchform.name" style="width:120px;"  placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="手机">
                            <el-input v-model="searchform.mobile" style="width:120px;"  placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号">
                            <el-input v-model="searchform.idNumber" style="width:120px;"  placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" @click="onSubmit"  class="mybotton">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="searchright">
                    <el-button type="primary"  @click="openAdd" >+添加</el-button>
                    <el-button type="primary"  @click="exportaction" icon="el-icon-bottom" :loading="exportloading">导出</el-button>
                </div>
            </div>
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="name"
                    label="姓名">
                    </el-table-column>
                    <el-table-column
                    prop="idNumber"
                    label="身份证">
                    </el-table-column>
                    <el-table-column
                    prop="mobile"
                    label="手机号">
                    </el-table-column>
                    <el-table-column
                    prop="preReportTime"
                    label="推广时间">
                        <template slot-scope="scope">
                            {{scope.row.preReportTime}} <span>{{timeitem[scope.row.preReportTimeQuantum-1].title}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="jobName"
                    label="是否入职">
                        <template slot-scope="scope">
                            <el-tag type="info"  v-if="scope.row.isReport==0">未入职</el-tag>
                            <el-tag type="success"  v-if="scope.row.isReport==1">已入职</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    align="center"
                    width="300"
                    label="附件">
                        <template slot-scope="scope">
                            <div class="listpicbox">
                                <div class="listpicitem" v-for="(item,index) in scope.row.attachment.split(',')" >
                                    <el-image
                                        style="width: 50px; height: 50px"
                                        :src="item"
                                        :preview-src-list="[item]">
                                    </el-image>
                                </div>
                            </div>

                            <!-- <span v-for="(item,index) in scope.row.attachment.split(',')">{{item}}</span> -->
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="name"
                    width="260"
                    label="操作">
                        <template slot-scope="scope">
                            <!-- <el-popconfirm title="确认标记为已赴约吗？" @confirm="sign(scope.row)">
                                <el-button type="primary" slot="reference" plain size="small">标记为已赴约</el-button>
                            </el-popconfirm> -->
                            <el-button   :disabled="scope.row.isReport==0?false:true" size="mini" type="primary" @click="sign(scope.row)" slot="reference" >标记为已入职</el-button>
                            <el-button    size="mini" type="primary" @click="openEdit(scope.row)" slot="reference" >编辑</el-button>
                            <el-button    size="mini" type="primary" @click="deleteItem(scope.row)" slot="reference" >删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page v-if="total!=0" :total="total" :pageSize="pageSize" :current="pageNo" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <Editcheckin :showEdit.sync="showEdit" :selectobj="selectobj" @getList="getList" @clearselect="clearSelect" />
        <addCheckin :showAdd.sync="showAdd"  @getList="getList"  />
    </div>
</template>
<script>
import _api from "@/api/index";
import apiUrl from "@/api/public";
import axios from 'axios';
import Editcheckin from './editCheckin.vue'
import addCheckin from "./addCheckin.vue";
export default {
    components:{
        Editcheckin,
        addCheckin
    },
    data(){
        return{
            searchform:{isReport:null,preReportTime:null,preReportTimeQuantum:null,name:null,idNumber:null,mobile:null},
            loading:false,
            exportloading:false,
            list:[],
            value1:[],
            total:0,
            showAdd:false,
            showEdit:false,
            selectobj:null,
            pageSize:10,
            pageNo:1,
            timeitem:[
                {id:1,title:"8:30～9:30"},
                {id:2,title:"9:30～10:30"},
                {id:3,title:"10:30～11:30"},
                {id:4,title:"13:30～14:30"},
                {id:5,title:"14:30～15:30"},
                {id:6,title:"15:30～16:30"},
                {id:7,title:"16:30～17:00"},
            ]
        }
    },
    created(){
        this.getList()
    },
    methods:{
        openAdd(){
            this.showAdd=true
        },
        sign(row){
            this.$confirm('确认标记为已入职吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                _api.post(apiUrl.forward,{id:row.id}).then(res=>{
                    if(res.code==200){
                        this.$message.success("标记成功！")
                        this.getList()
                    }else{
                        this.$message.error("标记失败！")
                    }
                })
            }).catch(() => {
            });
            console.log(56)

        },
        getList(){
            this.loading=true
            _api.get(apiUrl.forward,{pageNum:this.pageNo,pageSize:this.pageSize,...this.searchform}).then(res=>{
                console.log(res)
                this.loading=false
                if(res.code==200){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        exportaction(){//导出
            this.exportloading=true
             axios.post("/api/"+apiUrl.export,this.searchform,{ responseType: 'blob' }).then(res=>{
                 console.log(res)
                var blob = new Blob([res.data]);
                this.exportloading=false
                var downloadElement = document.createElement('a');
                var href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                downloadElement.download = new Date().getTime() + '.xlsx'; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
        },
        onSubmit(){
            this.pageNo=1
            this.getList()
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
        openEdit(row){
            this.selectobj=row
            this.showEdit=true
        },
        clearSelect(){
            this.selectobj=null
        },
        deleteItem(row){
            console.log(row)
            this.$confirm('此操作将永久删除信息卢, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _api.delete('/legal',row.id).then(res=>{
                    if(res.code==200){
                        this.$message.success("删除成功！")
                        this.getList()
                    }
                })
            }).catch(() => {

            });
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/booth.css";
.serarch{ overflow: hidden;}
.listpicbox{ display: flex; flex-wrap: wrap; justify-content: center;}
.listpicitem{ margin-right:5px;}
</style>
